<script setup lang="ts">
import { UI05Tooltip } from 'ui-05'
import { storeToRefs } from 'pinia'
import CCCheckout from '../CCCheckout/CCCheckout.vue'
import { useResizeStore } from '~/store/resize'
import { useProductStore } from '~/store/modal/product'
import { useCartStore } from '~/store/cart'
import { ErrorSerializer } from '~~/serializer/Error'
import CouponError from '~/serializer/CouponError'
import type { NewOrder } from '~/type/order/NewOrder'
import { useUserStore } from '~/store/user'
import checkCartChanges from '~/helper/checkCartChanges'
import { useCartChangedModal } from '~/store/modal/cartChanged'
import { useCheckoutStoreModal } from '~/store/modal/checkout'
import { useServiceStore } from '~/store/service'
import { useDeliveryStore } from '~/store/delivery'

const { $api, $emitter, $error } = useNuxtApp()
const resizeStore = useResizeStore()
const cartStore = useCartStore()
const userStore = useUserStore()
const deliveryStore = useDeliveryStore()
const { deliveryDate, deliveryType } = storeToRefs(useDeliveryStore())
const { setChecked: setCartChangedChecked } = useCartChangedModal()
const { enable: enableProduct } = useProductStore()
const checkoutModalStore = useCheckoutStoreModal()
const { isDeliveryDelayed } = storeToRefs(useServiceStore())
const { cartAmount, list, price, promocode, payment, replacement, otherProduct, cards } = storeToRefs(cartStore)
const isProductsUnavailable = computed(() => list.value.some(el => el.limit === 0))
const isMobile = computed(() => resizeStore.resize && resizeStore.resize < 1000)

const warehouseComment = useCookie<string | undefined>('warehouseComment', {
  expires: new Date(),
  maxAge: 60 * 60 * 24 * 3,
})

const orderCreateLoading = ref(false)
const orderCreateError = ref<string | boolean>()
const order = ref<NewOrder>({
  productAvailabilityConditionID: replacement.value ?? '',
  paymentCard: cards.value[0],
  payment: cards.value[0] ? payment.value[0] : undefined,
  leaveAtTheDoor: false,
  commentWarehouse: warehouseComment.value,
  deliveryDate: deliveryStore.deliveryDate,
} as NewOrder)
const couponRules = ref({})
const checkoutRef = shallowRef<InstanceType<typeof CCCheckout> | null>(null)

watch(() => cards.value.length, () => {
  order.value = { ...order.value, paymentCard: cards.value[0], payment: cards.value[0] && payment.value[0] }
})

watch(() => deliveryDate.value, (newValue) => {
  order.value.deliveryDate = newValue
})

onMounted(() => {
  checkCartChanges()
})

function onCheckoutClick() {
  checkoutModalStore.enable()
  /*
    класс мы добавляем для того, чтобы сама страница не скроллилась при  скролле окна
  */
  if (isMobile.value)
    document.body.classList.add('overflow-body')

  $emitter.emit('begin-checkout', { product: list.value, currentValue: price.value?.total })
}

function onCheckoutClose() {
  checkoutModalStore.disable()
  if (isMobile.value && document.body.classList.contains('overflow-body'))
    document.body.classList.remove('overflow-body')
}

function onCheckoutChange<K extends keyof NewOrder>(prop: K, val: NewOrder[K]) {
  order.value[prop] = val
}

function checkout() {
  checkoutRef.value?.onCheckout({
    onSuccess: async () => {
      orderCreateLoading.value = true
      try {
        const response = (await $api.order.create(order.value))?.result
        $emitter.emit('after-checkout', {
          user: userStore.data!,
          order: {
            id: response.orderID,
            product: list.value,
            price: { total: price.value?.total, delivery: price.value.delivery },
            coupon: promocode.value,
            paymentMethod: order.value.payment.name,
          },
        })
        checkoutModalStore.disable()
        cartStore.reset()
        setCartChangedChecked(false)
        deliveryStore.setDeliveryDate()
        // Оплата по не сохраненной карте -- редирект на страницу банка, по сохраненной -- сразу на главную.
        window.location.replace(response.payed ? '/' : response.paymentURL)
      }
      catch (e: any) {
        orderCreateLoading.value = false
        checkoutModalStore.enable()
        const error = ErrorSerializer(e)
        if (error.type === 'api')
          orderCreateError.value = e?.response?.data?.error?.description || 'Ошибка, попробуйте позже'

        throw new $error.simple(error)
      }
    },
    onError: (error: any) => {
      UI05Tooltip({
        type: 'error',
        title: 'Ошибка',
        description: error.paymentType ? 'Не выбран способ оплаты' : 'При оформлении заказа произошла ошибка',
      })
    },
  })
}

const addPromocodeApi = await useActionEntity($api.order.addPromocode, {
  params: {
    code: '',
    deliveryType: deliveryType.value,
  },
  onSuccess: async () => {
    await checkCartChanges()
    $emitter.emit('apply-promocode', { status: 'success', discount: price.value.sale })
  },
  onError: ({ serialized }) => {
    if (serialized?.fields)
      couponRules.value = CouponError(serialized.fields)

    UI05Tooltip({
      type: 'error',
      title: 'Ошибка',
      description: serialized?.message,
    })
    $emitter.emit('apply-promocode', { status: 'error' })
  },
})

const removePromocodeApi = await useActionEntity($api.order.removePromocode, {
  params: {
    code: '',
    deliveryType: deliveryType.value,
  },
  onSuccess: checkCartChanges,
})

function onLeaveOutTheDoor(value: boolean) {
  order.value.leaveAtTheDoor = value
}

function onCommentChange(value: string | undefined = undefined) {
  warehouseComment.value = value
  order.value.commentWarehouse = warehouseComment.value
}
</script>

<template>
  <div class="checkout-controller">
    <div class="checkout-controller__wrap">
      <div
        class="checkout-controller__content-wrapper"
        :class="{
          'checkout-controller__content-wrapper_not-more-products': cartAmount && !otherProduct.length,
          'checkout-controller__content-wrapper_delivery-delayed': isDeliveryDelayed,
        }"
      >
        <CCCart
          :is-checkout-active="checkoutModalStore.active"
          :order-create-loading="orderCreateLoading"
          :is-products-unavailable="isProductsUnavailable"
          :list="list"
          :other-products="otherProduct"
          :initial-comment="order.commentWarehouse"
          @on-checkout-click="onCheckoutClick"
          @cart-is-empty="onCommentChange"
          @on-pay-click="checkout"
          @comment-change="onCommentChange"
        />
        <div v-if="cartAmount && otherProduct.length" class="checkout-controller__more-products">
          <div class="checkout-controller__more-products-title">
            Может что-то ещё?
          </div>
          <ProductGrid
            :list="otherProduct"
          >
            <template #product="{ product }">
              <ProductCard
                :key="product.id"
                :product="product"
                list-name="Может что-то еще"
                @click="enableProduct({ active: true, id: product.id, path: product.path })"
              />
            </template>
          </ProductGrid>
        </div>
        <div v-show="checkoutModalStore.active" class="checkout-controller__overlay">
          <CCCheckout
            ref="checkoutRef"
            :price="price"
            :payment-methods="payment"
            :promocode="promocode"
            :cards="cards"
            :replacement-list="replacement"
            :order="order"
            :api-error="orderCreateError"
            :coupon-error="addPromocodeApi.errorValue.value || removePromocodeApi.errorValue.value"
            :coupon-add-status="addPromocodeApi?.status.value"
            :coupon-rules="couponRules"
            :delivery-time-data="cartStore.deliveryTimeData"
            @change="onCheckoutChange"
            @comment-change="onCommentChange"
            @leave-at-the-door-value-changed="onLeaveOutTheDoor"
            @close="onCheckoutClose"
            @add-promocode="addPromocodeApi.request({ code: $event, deliveryType })"
            @remove-promocode="removePromocodeApi.request({ code: $event, deliveryType })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.checkout-controller {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 22px;

  &__wrap {
    width: 100%;
    height: 100%;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
  }

  &__overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    height: calc(100% - 94px);
    background-color: #f5f5f5;
  }

  &__more-products {
    display: none
  }

  @media (max-width: 1000px) {
    height: 100%;
    max-height: initial;
    overflow: initial;
    border: none;

    &__content-wrapper {
      padding: 0;
      overflow: initial;

      &_delivery-delayed {
        padding: 0 0 35px;
      }
    }

    &__overlay {
      height: 100%;
    }

    &__more-products {
      display: block;
      margin-top: 10px;
      margin-bottom: 245px;

      &-title {
        margin-bottom: 15px;
        font-size: 19px;
        font-weight: 500;
        line-height: 21px;
      }
    }
  }
}
</style>
