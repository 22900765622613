<script setup lang="ts">
import { UI05FormElement, UI05Textarea } from 'ui-05'
import { useElementSize, useScroll } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/modal/auth'
import { useProductStore } from '~/store/modal/product'
import { useUserStore } from '~/store/user'
import { useCartStore } from '~/store/cart'
import type { Product } from '~/type/product/Product'
import { useClearBasketStore } from '~~/store/modal/clearBasket'
import { timeExpirationCheck } from '~/helper/timeExpirationCheck'
import { useDeliveryStore } from '~/store/delivery'
import { useAddressStore } from '~/store/address'
import { useChooseAddressStore } from '~/store/modal/chooseAddress'
import checkCartChanges from '~/helper/checkCartChanges'
import { useServiceModal } from '~/store/modal/service'

interface Props {
  /**
   * Состояние активности оформления (нужно для состояния кнопки "Оплатить" )
   */
  isCheckoutActive: boolean
  /**
   * Есть ли хоть один товар, которого нет в наличии
   */
  isProductsUnavailable: boolean
  list: Product[]
  otherProducts: Product[]
  /**
   * Состояние загрузки оформления заказа
   */
  orderCreateLoading?: boolean
  error?: boolean
  initialComment?: string
}

withDefaults(defineProps<Props>(), {
  active: false,
  error: false,
  orderCreateLoading: false,
  initialComment: '',
})
const emit = defineEmits<{
  (event: 'on-pay-click' | 'on-checkout-click'): void
  (event: 'on-delete', value: number): void
  (event: 'cart-is-empty'): void
  (event: 'comment-change', value: string): void
}>()

const userStore = useUserStore()
const cartStore = useCartStore()
const authStore = useAuthStore()
const productModalStore = useProductStore()
const clearBasket = useClearBasketStore()
const deliveryStore = useDeliveryStore()
const addressStore = useAddressStore()
const chooseAddressStore = useChooseAddressStore()
const serviceModalStore = useServiceModal()
const cartEl = shallowRef()
const productListEl = shallowRef()
const { arrivedState: arrivedStateMobile } = useScroll(window, {
  offset: {
    bottom: 10,
  },
})
const { arrivedState } = useScroll(cartEl)
const { height: cartHeight } = useElementSize(cartEl)
const { height: productListHeight } = useElementSize(productListEl)

const isCheckoutAvailable = computed(() => (cartStore.serviceType === 'no-delivery' || cartStore.serviceType === 'unavailable') && !!addressStore.current)

const { isGuest } = storeToRefs(userStore)

const paymentButtonDisabled = computed(() => {
  return (!deliveryStore.deliveryDate && deliveryStore.deliveryType === 'delayed')
  || (deliveryStore.deliveryType && !cartStore.deliveryTimeData[`${deliveryStore.deliveryType}`])
  || (!deliveryStore.deliveryType && cartStore.deliveryTimeData?.delayed)
})

let unsubscribe: () => void | undefined
onMounted(() => {
  unsubscribe = cartStore.$onAction((mutation) => {
    if (mutation.name === 'setCart' || mutation.name === 'reset') {
      mutation.after(() => {
        if (mutation.store.cartAmount === 0) {
          deliveryStore.setDeliveryDate()
          emit('cart-is-empty')
        }
      })
    }
  })
})
onUnmounted(() => {
  unsubscribe?.()
})

async function onPayClick() {
  // Отдельная проверка, чтобы не отправлять запрос при disabled кнопке
  if (paymentButtonDisabled.value)
    return undefined

  const cartChanged = await checkCartChanges()
  if (cartChanged) {
    return undefined
  }
  else if (deliveryStore.deliveryType === 'delayed') {
    if (deliveryStore.deliveryDate && timeExpirationCheck(deliveryStore.deliveryDate.time))
      deliveryStore.setExpiredDateValue(true)
    else if (cartStore.serviceType === 'unavailable')
      serviceModalStore.enable()
    else
      !cartChanged && emit('on-pay-click')
  }
  else {
    !cartChanged && emit('on-pay-click')
  }
}

async function onCheckoutClick() {
  await checkCartChanges()
  if (!addressStore.current)
    chooseAddressStore.enable()
  else
    ((cartStore.serviceType && cartStore.serviceType !== 'loaded') && !cartStore.deliveryTimeData.delayed) ? serviceModalStore.enable() : emit('on-checkout-click')
}
</script>

<template>
  <div class="cart">
    <h2 class="cart__title">
      Корзина
    </h2>
    <Unavailable
      v-if="cartStore.cartAmount === 0 && !isCheckoutActive"
      icon="darkotik"
      class="cart__overlay cart__overlay_empty"
    >
      <p class="cart__description">
        Ломтик ждет пока вы добавите товары
      </p>
    </Unavailable>
    <div
      v-else
      ref="cartEl"
      class="cart__product"
      :class="{ cart__product_unavailable: isProductsUnavailable }"
    >
      <button
        v-if="cartStore.cartAmount"
        class="cart__delete-all"
        @click="clearBasket.enable()"
      >
        Очистить
      </button>
      <CCCartProductList
        v-slot="{ product }"
        ref="productListEl"
        :list="list"
      >
        <CCCartProduct
          :class="{ 'cart__unavailable-product': cartStore.showOverlay }"
          :product="product"
          @decrease="$emitter.emit('cart-product-remove', { product: { ...product, quantity: product.quantity }, cart: cartStore.state! })"
          @open="productModalStore.enable({ active: $event.status, id: $event.id, path: $event.path })"
        />
      </CCCartProductList>
      <UI05FormElement
        title="Комментарий для сборщика"
        hint-min-height="0"
      >
        <UI05Textarea
          :model-value="initialComment"
          placeholder="Любые детали, которые важны при выборе продуктов"
          data-e2e="checkout-comment-warehouse"
          :height="60"
          dynamical-height
          @update:model-value="emit('comment-change', $event)"
        />
      </UI05FormElement>
    </div>
    <div v-if="cartStore.cartAmount === 0 && !isCheckoutActive" class="cart__content-buttons cart__content-buttons_empty">
      <div class="cart__content-button cart__content-button_empty">
        Добавьте товар в корзину <span class="cart__content-button-price">0 ₽</span>
      </div>
    </div>
    <div v-else-if="isProductsUnavailable" class="cart__content-buttons cart__content-buttons_not-available">
      Удалите товары которых нет в наличии,
      чтобы перейти к оформлению заказа
    </div>
    <div
      v-else-if="cartStore.cartAmount"
      class="cart__content-buttons"
      :class="{ 'cart__content-buttons_checkout-active': isCheckoutActive }"
    >
      <template v-if="productListHeight > cartHeight && !isCheckoutActive">
        <div v-if="!arrivedState.bottom" class="cart__scroll-overlay cart__scroll-overlay_desktop" />
        <div v-if="!arrivedStateMobile.bottom && !otherProducts.length" class="cart__scroll-overlay cart__scroll-overlay_mobile" />
      </template>

      <div v-if="cartStore.serviceType === 'loaded' && !isCheckoutActive" class="cart__delivery-delayed-note">
        <DeliveryDelayedNote />
      </div>

      <template v-if="!isCheckoutActive">
        <div v-if="cartStore.deliveryTimeData?.delayed || cartStore.deliveryTimeData?.express" class="cart__delivery-type">
          <CCDeliveryType
            :delivery-time-data="cartStore.deliveryTimeData"
            :time-select-modal-button-show="false"
          />
        </div>
        <div v-else class="cart__free-delivery">
          <CCFreeDeliveryRange
            :delivery="cartStore.price.delivery"
            :total-price="cartStore.price.product"
            :free-delivery="cartStore.freeDeliveryPrice"
          />
        </div>
      </template>
      <div
        v-if="isGuest"
        class="cart__content-button cart__content-button_auth"
        @click="authStore.enable({})"
      >
        Авторизоваться
      </div>
      <div v-else-if="orderCreateLoading" class="cart__content-button">
        <div class="cart__content-button-text cart__content-button-text_loading" />
      </div>
      <div
        v-else-if="isCheckoutActive"
        class="cart__content-button-text cart__content-button"
        :class="{ 'cart__content-button-text cart__content-button_disabled': paymentButtonDisabled }"
        @click="onPayClick"
      >
        Оплатить
        <p class="mr-5">
          <span data-e2e="discount-total-cost"> {{ cartStore.price.total }}₽</span>
          <span v-if="cartStore.price.sale" class="cart__content-button-text-without-discounts">{{ cartStore.price.total + cartStore.price.sale }}₽</span>
        </p>
      </div>
      <template v-else>
        <div
          v-if="isCheckoutAvailable"
          class="cart__content-button cart__content-button-text cart__content-button_empty"
        >
          Сейчас мы не доставляем
          <span class="cart__content-button-price"> {{ cartStore.price.total }}₽</span>
        </div>
        <div
          v-else
          class="cart__content-button cart__content-button-text"
          @click="onCheckoutClick"
        >
          Оформить заказ
          <span class="cart__content-button-price"> {{ cartStore.price.total }}₽</span>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.cart {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__delivery-delayed-note {
    margin-bottom: 15px;
  }

  &__scroll-overlay {
    position: absolute;
    top: -99px;
    left: 0;
    width: 100%;
    height: 99px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);

    &_mobile {
      display: none;
    }
  }

  &__delete-all {
    position: absolute;
    top: 23px;
    left: calc(100% - 100px);
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
    background: transparent;
    border: none;
    opacity: 0.5;

    &:hover {
      opacity: 0.7;
    }
  }

  &__description {
    width: 160px;
    font-size: 16px;
    line-height: 22px;
  }

  &__unavailable-product {
    pointer-events: none;
    opacity: 0.3;
  }

  &__error {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__overlay {
    position: static;
    z-index: var(--z-blackout);
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 110px);
    background-color: rgba(255, 255, 255, 0.9);

    &_empty {
      height: 100%;
      background-color: #fff;
      backdrop-filter: none;
    }
  }

  &__product {
    flex-grow: 1;
    height: 100%;
    padding: 0 20px 10px;
    overflow-y: scroll;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;

    &_unavailable {
      height: 100%;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
  }

  &__title {
    flex-shrink: 0;
    padding:  20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }

  &__trash-icon {
    width: 23px;
    height: 23px;
    margin-left: auto;
    cursor: pointer;
    background-image: svg-load('./asset/trash.svg', fill=transparent);
    background-repeat: no-repeat;
  }

  &__content-buttons {
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    min-height: fit-content ;
    padding: 15px;
    background-color: #fff;
    border-radius: 21px;
    box-shadow: 0px -8px 40px 0px #3232470F, 0px -8px 8px 0px #32324705;

    &_empty {
      position: static;
      z-index: var(--z-modal);
    }

    &_not-available {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #00B0B8;
    }
  }

  &__content-button {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 21px 15px;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    cursor: pointer;
    background-color: #00B0B8;
    border-radius: 22px;

    &_disabled {
      opacity: 0.4;
    }

    &_empty {
      justify-content: space-between;
      color: #818181;
      background-color: #f8f8f8;
    }

    &_auth {
      font-size: 18px;
      font-weight: 500;
      background-color: #fdca54;
    }

    &_off-hours {
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
      color: #000;
      text-align: center;
      background-color: #eee;

      p.mr-5 {
        display: none;
      }

      @media (max-width: 1000px) {
        justify-content: space-between;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;

        p.mr-5 {
          display: block;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
  }

  &__content-button-price {
    flex-shrink: 0;
  }

  &__content-button-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 64px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    &_loading {
      height: 22px;
      pointer-events: none;
      background-image: url(./asset/loading-dots.svg);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 13%;
    }

    &-without-discounts {
      position: relative;
      margin-left: 10px;
      font-size: 20px;
      line-height: 23px;
      color: rgb(255, 255, 255, 0.5);

      &::before {
        position: absolute;
        top: 45%;
        left: -2px;
        width: 100%;
        height: 1px;
        content: '';
        background-color: #fff;
        border-radius: 10px;
        transform: rotate(-17deg);
      }
    }
  }

  &__delivery-type {
    margin-bottom: 15px;
  }

  @media (max-width: 1100px) {

    &__delete-all {
      left: calc(100% - 90px);
    }

    &__title,  &__product  {
      padding: 0 5px;
    }

    &__title {
      margin: 15px 0 15px;
    }
  }

  @media (max-width: 1000px) {
    height: calc(100dvh - 183px);
    touch-action: none;

    &__delete-all {
      top: 18px;
      left: calc(100% - 85px);
    }

    &__scroll-overlay {

      &_desktop {
        display: none;
      }
    }

    &__title-wrap {
      padding-bottom: 10px;
    }

    &__title {
      margin: 15px 0 5px;
      font-size: 20px;
      line-height: 23px;
    }

    &__overlay {
      position: static;
      height: auto;
    }

    &__product {
      padding-bottom: 20px;
    }

    &__content-buttons {
      position: relative;
      flex-shrink: 0;
      padding: 0 15px 15px 15px;
      margin: 0 -15px;
      border-radius: 0;
      box-shadow: none;

      &:before {
        position: absolute;
        top: -15px;
        left: 0;
        display: block;
        width: 100%;
        height: 15px;
        content: "";
        background-color: white;
        border-radius: 20px 20px 0 0 ;
        box-shadow: 0 -8px 40px 0 rgba(50, 50, 71, .059), 0 -8px 8px 0 #32324705;
      }

      &_empty {
        position: absolute;
      }

      &_checkout-active {
        position: fixed;
        z-index: var(--z-blackout);
        padding: 20px 10px 20px;
        margin: 0 ;
        margin-bottom: 65px;
        border-radius: 20px 20px 0 0 ;
        box-shadow: 0 -8px 40px 0 rgba(50, 50, 71, .059), 0 -8px 8px 0 #32324705;

        &:before {
          height: 0;
          box-shadow: none;
        }
      }
    }
  }
}
</style>
