<script setup lang="ts">
import { UI05Button, UI05FormElement, UI05InputText, UI05Note, UI05Textarea } from 'ui-05'
import { storeToRefs } from 'pinia'
import { useAddressStore } from '~/store/address'
import { useChooseAddressStore } from '~/store/modal/chooseAddress'
import type { CheckoutPayment, CheckoutPrice } from '~/type/order/Checkout'
import type { PaymentCard } from '~/type/PaymentCard'
import type { NewOrder } from '~/type/order/NewOrder'
import type CouponError from '~/type/order/CouponError'
import type { Status } from '~/composables/smart'
import type { DeliveryTime } from '~/type/DeliveryTime'
import { useSelectPaymentModalStore } from '~/store/modal/selectPayment'
import { useCartStore } from '~/store/cart'

interface Props {
  deliveryTimeData: DeliveryTime
  order: NewOrder
  paymentMethods: CheckoutPayment[]
  promocode?: string
  apiError?: string | boolean
  price: CheckoutPrice
  couponAddStatus?: Status
  couponRules: CouponError
  cards: PaymentCard[]
  couponError?: {
    message?: string
  }
}
const props = defineProps<Props>()

const emit = defineEmits<{
  <F extends Partial <NewOrder>, K extends keyof NewOrder>(event: 'change', property: K, value?: F[K]): void
  (event: 'close'): void
  (event: 'remove-promocode', value: string): void
  (event: 'add-promocode', value: string): void
  (event: 'leave-at-the-door-value-changed', value: boolean): void
}>()

interface Observer {
  validate(): Promise<{ errors: string[]; valid: boolean }>
}

defineExpose({
  onCheckout: validateFunction,
})

const { $emitter } = useNuxtApp()

const chooseAddressStore = useChooseAddressStore()
const selectPaymentModalStore = useSelectPaymentModalStore()
const cartStore = useCartStore()
const showWrongPaymentModal = ref(false)
const showPromocodeModal = ref(false)
const showPromocodeRulesModal = ref(false)
const disabledPaymentMethod = ref('')
const promocodeValue = ref('')
const { isCurrentExist: isCurrentAddressExist, formatted: formattedAddress } = storeToRefs(useAddressStore())
const observerRef = shallowRef<Observer>()
// При изначальном выборе онлайн оплаты должно быть отсутствие выбора банковской карты
const initialSelectOnline = ref(true)
const errorMessage = computed(() => {
  // Если с бэка пришла причина невозможности применить промокод, возвращаем шаблонный текст с причиной
  if (props.couponError?.message === 'Не удалось применить промокод')
    return 'Почему невозможно применить промокод?'
  else if (props.couponError?.message)
    return props.couponError?.message
  else return null
})
const showCheckoutModal = computed(() => selectPaymentModalStore.active || showPromocodeModal.value || showPromocodeRulesModal.value || showWrongPaymentModal.value)
const showListCards = computed(() => props.cards.length > 0)

watch(() => props.couponAddStatus, (value) => {
  if (value === 'success')
    showPromocodeModal.value = false
})

function validateFunction({ onError, onSuccess }: { onError: (errors: any) => void; onSuccess: () => void }) {
  observerRef.value?.validate().then(({ errors, valid }: { errors: string[]; valid: boolean }) => {
    valid ? onSuccess() : onError(errors)
  })
}

function selectOnlineMethod(paymentMethod: CheckoutPayment, card?: PaymentCard) {
  initialSelectOnline.value = false
  selectPaymentModalStore.disable()
  emit('change', 'payment', paymentMethod)
  emit('change', 'paymentCard', card)
  selectPaymentModalStore.setPaymentCard(card)
}
</script>

<template>
  <ValidationForm
    ref="observerRef"
    v-slot="validationFormSlot"
    class="checkout"
    :initial-values="{ paymentType: order.payment?.name }"
    as="div"
  >
    <div class="checkout__wrap">
      <div class="checkout__top">
        <div class="checkout__title">
          Оформление заказа
        </div>
        <div class="checkout__close" @click="emit('close')" />
      </div>

      <div v-if="cartStore?.deliveryTimeData?.delayed || cartStore?.deliveryTimeData?.express" class="checkout__select-delivery">
        <div class="checkout__select-delivery-title">
          Способ доставки
        </div>
        <div class="checkout__select-delivery-description">
          Выберите способ доставки
        </div>
        <CCDeliveryType
          :price="price"
          :note-show="false"
          :delivery-time-data="deliveryTimeData"
        />
      </div>

      <div class="checkout__address">
        <div class="checkout__address-title">
          Адрес доставки
        </div>
        <div class="checkout__address-description">
          Выберите aдрес доставки
        </div>
        <div class="checkout__change-address" @click="chooseAddressStore.enable({})">
          {{ isCurrentAddressExist ? formattedAddress : 'Указать на карте' }}
        </div>
        <div class="checkout__address-detail">
          <div class="checkout__address-detail-col">
            <UI05FormElement title="Подъезд" hint-min-height="0">
              <UI05InputText :model-value="order?.entrance" @update:model-value="emit('change', 'entrance', $event)" />
            </UI05FormElement>
          </div>
          <div class="checkout__address-detail-col">
            <UI05FormElement title="Этаж" hint-min-height="0">
              <UI05InputText :model-value="order?.floor" @update:model-value="emit('change', 'floor', $event)" />
            </UI05FormElement>
          </div>
          <div class="checkout__address-detail-col">
            <UI05FormElement title="Квартира" hint-min-height="0">
              <UI05InputText :model-value="order?.apartment" @update:model-value="emit('change', 'apartment', $event)" />
            </UI05FormElement>
          </div>
          <div class="checkout__address-detail-col">
            <UI05FormElement title="Домофон" hint-min-height="0">
              <UI05InputText :model-value="order?.intercom" @update:model-value="emit('change', 'intercom', $event)" />
            </UI05FormElement>
          </div>
        </div>
        <UI05FormElement title="Комментарий для курьера" hint-min-height="0">
          <UI05Textarea
            data-e2e="checkout-comment-courier"
            dynamical-height
            placeholder="Упаковать в разные пакеты"
            :height="60"
            @update:model-value="emit('change', `commentCourier`, $event)"
          />
        </UI05FormElement>
        <div v-if="isCurrentAddressExist && order" class="checkout__leave-at-the-door">
          Оставить заказ у двери
          <CCToggleButton
            :active="order.leaveAtTheDoor"
            @click="emit('leave-at-the-door-value-changed', $event)"
          />
        </div>
      </div>
      <div class="checkout__payment">
        <p class="checkout__payment-title mb-5">
          Оплата
        </p>
        <p class="checkout__payment-subtitle mb-10">
          {{ order.payment?.id && Number(order.payment?.id) === 22 ? 'Заморозим сумму заказа на вашей карте, после получения сумма спишется' : 'Выберите тип оплаты' }}
        </p>
        <div
          class="checkout__payment-card "
          :class="[{ 'checkout__payment-card_error': validationFormSlot?.errors?.paymentType }]"
          @click="selectPaymentModalStore.enable"
        >
          <div class="checkout__payment-card-icon" />
          <span class="checkout__payment-text"> Способ оплаты </span>
          <div v-if="cartStore.currentPaymentCard" class="checkout__payment-value">
            <span class="checkout-card__type">{{ cartStore.currentPaymentCard?.type }}</span>
            <div class="checkout-card__number">
              {{ cartStore.currentPaymentCard.cardNumberShort }}
            </div>
          </div>
          <p v-else class="checkout__payment-value">
            {{ order.payment?.name }}
          </p>
          <div class="checkout__payment-icon_arrow" />
        </div>
        <div class="checkout__payment-promocode" @click="showPromocodeModal = true">
          <div class="checkout__payment-promocode-icon" />
          <span class="checkout__payment-text"> Промокод </span>
          <div v-if="promocode" class="checkout__payment-promocode-value">
            {{ promocode }}
            <div class="checkout__payment-promocode-remove" @click.stop="emit('remove-promocode', promocode), promocodeValue = ''" />
          </div>
          <div v-else class="checkout__payment-icon_arrow checkout__payment-promocode_arrow" />
        </div>
      </div>
      <UI05Note
        v-if="apiError"
        theme="reddish"
        class="mb-15"
      >
        <div class="checkout__api-error">
          {{ apiError }}
        </div>
      </UI05Note>
    </div>
    <div class="checkout__modal" :class="[{ checkout__modal_active: showCheckoutModal }]">
      <ValidationField
        v-slot="{ handleChange }"
        :rules="order.paymentCard ? '' : 'required'"
        name="paymentType"
      >
        <CCModal
          v-if="selectPaymentModalStore.active"
          title="Способ оплаты"
          @close="selectPaymentModalStore.disable"
        >
          <slot>
            <template v-for="select in paymentMethods">
              <div
                v-if="Number(select.id) === 22"
                :key="select.id"
                class="modal-bottom__main"
              >
                <template v-if="showListCards">
                  <div
                    v-for="card in cards"
                    :key="card.id"
                    class="modal-bottom__item modal-bottom__item_card"
                    :class="[{ 'modal-bottom__item_check': order.payment?.id === select.id && order.paymentCard?.id === card.id }]"
                    @click="() => {
                      selectOnlineMethod(select, card)
                      $emitter.emit('select-payment', 'Оплата сохранённой картой')
                      handleChange(order.payment)
                    }"
                  >
                    <ImgPaymentCard class="checkout-card__logo" :type="card.type" />
                    <span class="checkout-card__type">{{ card.type }}</span>
                    <span class="checkout-card__number">
                      {{ card.cardNumberShort }}
                    </span>
                  </div>
                  <div
                    class="modal-bottom__item"
                    :class="[{ 'modal-bottom__item_check': order.payment?.id === select.id && !order.paymentCard && !initialSelectOnline }]"
                    @click="() => {
                      selectOnlineMethod(select)
                      $emitter.emit('select-payment', select.name)
                      handleChange(order.payment)
                    }"
                  >
                    Другой картой
                  </div>
                </template>
                <div
                  v-else-if="!cards.length"
                  class="modal-bottom__item"
                  :class="[{ 'modal-bottom__item_check': order.payment?.id === select.id }]"
                  @click="() => {
                    selectOnlineMethod(select)
                    $emitter.emit('select-payment', select.name)
                    handleChange(order.payment)
                    selectPaymentModalStore.disable()
                  }"
                >
                  {{ select.name }}
                </div>
                <div
                  v-else
                  class="modal-bottom__item"
                  @click="() => {
                    showListCards = true
                  }"
                >
                  {{ select.name }}
                </div>
              </div>

              <div
                v-if="select.disabled"
                :key="select.id"
                class="modal-bottom__item"
                @click="() => {
                  selectPaymentModalStore.disable()
                  showWrongPaymentModal = true
                  disabledPaymentMethod = select.name
                  selectOnlineMethod(paymentMethods[0])
                  handleChange(order.payment)
                  $emitter.emit('select-payment', disabledPaymentMethod)
                }"
              >
                {{ select.name }}
              </div>
            </template>
          </slot>
        </CCModal>
      </ValidationField>
      <CCModal
        v-if="showPromocodeModal"
        title="Промокод"
        @close="showPromocodeModal = false"
      >
        <slot>
          <div class="modal-bottom__input">
            <!--
                Если по какой-то причине промокод не может примениться и бэк вернул нам эту причину, мы вешаем этот класс,
                чтобы показать ошибку с подчёркиванием, чтобы пользователь понял, что кликнув на неё, появится модалка
              -->
            <UI05FormElement
              class="form-element"
              :class="[{ 'form-element_modal': couponError?.message === 'Не удалось применить промокод' }]"
              hint-min-height="0"
              name="промокод"
              :error="errorMessage || undefined"
              @click-hint="showPromocodeModal = false, showPromocodeRulesModal = true"
            >
              <UI05InputText v-model="promocodeValue" placeholder="Введите промокод" />
            </UI05FormElement>
            <UI05Button
              v-if="couponAddStatus === 'loading' && !couponError"
              size="49"
              fluid
              theme="turquoise"
              loading
            />
            <UI05Button
              v-else-if="promocodeValue"
              size="49"
              theme="turquoise"
              fluid
              @click="emit('add-promocode', promocodeValue)"
            >
              Применить
            </UI05Button>
            <UI05Button
              v-else
              size="49"
              fluid
              disabled
            >
              Применить
            </UI05Button>
          </div>
        </slot>
      </CCModal>
      <CCModal
        v-if="showPromocodeRulesModal"
        :title="`Промокод ${promocodeValue}`"
        @close="showPromocodeRulesModal = false"
      >
        <slot>
          <div class="checkout__coupon-block">
            <div v-if="couponRules.sale" class="checkout__coupon-item">
              <div class="checkout__coupon-title">
                {{ couponRules.sale }}
              </div>
              <div class="checkout__coupon-subtitle">
                номинал
              </div>
            </div>
            <div v-if="couponRules.expirationDate" class="checkout__coupon-item">
              <div class="checkout__coupon-title">
                {{ couponRules.expirationDate }}
              </div>
              <div class="checkout__coupon-subtitle">
                срок действия
              </div>
            </div>
            <div v-if="couponRules.orderSum" class="checkout__coupon-item">
              <div class="checkout__coupon-title">
                от {{ couponRules.orderSum }}
              </div>
              <div class="checkout__coupon-subtitle">
                сумма заказа
              </div>
            </div>
            <div v-if="couponRules.assortment" class="checkout__coupon-item">
              <div class="checkout__coupon-icon">
                <img
                  class="checkout__coupon-darkstore-icon"
                  src="./asset/logo.svg"
                  alt="Ассортимент Близко"
                >
              </div>
              <div class="checkout__coupon-subtitle">
                ассортимент Близко
              </div>
            </div>
            <div v-if="!couponRules.isSingleUse" class="checkout__coupon-item">
              <div class="checkout__coupon-icon">
                <img src="./asset/single-use.svg" alt="Разовое применение">
              </div>
              <div class="checkout__coupon-subtitle">
                разовое применение
              </div>
            </div>
          </div>
          <UI05Button
            size="49"
            fluid
            theme="turquoise"
            @click="showPromocodeRulesModal = false"
          >
            Хорошо, понятно
          </UI05Button>
        </slot>
      </CCModal>
      <CCModal v-if="showWrongPaymentModal" @close="showWrongPaymentModal = false">
        <slot>
          <div class="checkout__wrong-payment">
            <div class="checkout__wrong-payment-img-wrap">
              <img src="./asset/wrong-payment.svg" :alt="`Оплата ${disabledPaymentMethod} пока не работает`">
            </div>
            <div class="checkout__wrong-payment-title">
              Оплата {{ disabledPaymentMethod.toLowerCase() }} пока не работает
            </div>
          </div>
          <UI05Button
            size="49"
            fluid
            theme="turquoise"
            @click="showWrongPaymentModal = false"
          >
            Оплатить картой
          </UI05Button>
        </slot>
      </CCModal>
    </div>
  </ValidationForm>
</template>

<style lang="postcss" scoped>
.checkout {
  max-width: 700px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &__select-delivery {
    padding: 15px;
    margin-top: 1px;
    margin-bottom: 5px;
    background-color: #fff;
    border-radius: 0px 0px 20px 20px;

    &-title {
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
    }

    &-description {
      margin-bottom: 15px;
      font-size: 12px;
      line-height: 16px;
      color: #A8A8A8;
    }
  }

  &__leave-at-the-door {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0 5px;
    font-size: 16px;
    line-height: 18px;
  }

  &__top {
    position: relative;
  }

  &__title {
    padding: 20px 20px 16px;
    margin-bottom: 1px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    background-color: #fff;
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 21px;
    width: 11px;
    height: 11px;
    cursor: pointer;
    background-image: svg-load('./asset/close.svg');
    background-repeat: no-repeat;
    background-size: 11px;
    transition: background-image 0.2s ease-out;

    &:hover {
      background-image: svg-load('./asset/close.svg', fill=#E30613);
    }
  }

  &__address {
    padding: 15px;
    margin-bottom: 5px;
    background-color: #fff;
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0px 0px 20px 20px;

    &-title {
      padding-bottom: 5px;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      color: #2c2e33;
    }

    &-description {
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 16px;
      color: #A8A8A8;
      border-bottom: 1px solid #f3f3f3;
    }
  }

  &__change-address {
    padding-right: 20px;
    padding-left: 30px;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    background-image: url(./asset/checkout-map.svg), url(./asset/arrow-right.svg);
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: 20px, 7px 11px;
  }

  &__address-detail {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    row-gap: 15px;
    column-gap: 16px;
    margin: 20px 0px 5px;
  }

  &__address-detail-col {
    max-width: 160px;

    &:last-child {
      max-width: none;
    }
  }

  &__api-error {
    word-break: break-word;
  }

  &__payment {
    padding: 15px 20px 20px;
    background-color: #fff;

    &-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
    }

    &-subtitle {
      font-size: 12px;
      line-height: 16px;
      color: #a8a8a8;
    }

    &-text {
      margin-right: 5px;
      margin-left: 13px;
      font-size: 16px;
      line-height: 16px;
    }

    &-card_error{

      .checkout__payment-card-icon,
      .checkout__payment-text
      {
        filter: invert(16%) sepia(85%) saturate(6104%) hue-rotate(351deg) brightness(86%) contrast(107%);
      }

    }

    &-card,
    &-promocode {
      display: flex;
      align-items: center;
      padding: 12px 0px 12px 3px;
      cursor: pointer;
      border-top: 1px solid #f3f3f3;
    }

    &-promocode {
      padding: 12px 0px 0px 3px;

      &_arrow {
        margin-left: auto;
      }

      &-value {
        position: relative;
        padding: 5px 25px 5px 10px;
        margin-left: auto;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
        background-color: #00B0B8;
        border-radius: 5px;
      }

      &-remove {
        position: absolute;
        top: 50%;
        right: 5px;
        width: 10px;
        height: 10px;
        padding: 5px 10px 5px 5px;
        background-image: svg-load('./asset/close.svg', fill=#fff);
        background-repeat: no-repeat;
        background-size: 10px;
        transform: translateY(-50%);
      }
    }

    &-card-icon {
      width: 24px;
      height: 24px;
      background-image: url(./asset/way-payment.svg);
      background-size: 24px auto;
    }

    &-promocode-icon {
      width: 24px;
      height: 24px;
      background-image: url(./asset/promocode.svg);
      background-size: 24px auto;
    }

    &-icon_arrow {
      width: 5px;
      height: 10px;
      background-image: url(./asset/arrow-right.svg);
      background-repeat: no-repeat;
      background-size: 5px auto;
    }

    &-value {
      display: flex;
      margin: 0 16px 0 auto;
      font-size: 16px;
      line-height: 16px;
      color: #818181;
      text-align: right;
    }
  }

  &__coupon-block {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-bottom: 20px;
  }

  &__coupon-item {
    width: 33.3%;
  }

  &__coupon-title {
    margin-bottom: 4px;
    font-size: 19px;
    font-weight: 500;
    line-height: 21px;
  }

  &__coupon-subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #818181;
  }

  &__coupon-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-bottom: 4px;
  }

  &__coupon-darkstore-icon {
    width: 26px;
    height: 26px;
  }

  &__wrong-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  &__wrong-payment-img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__wrong-payment-title {
    max-width: 190px;
    font-size: 19px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
  }

  @media (max-width: 1300px) {

    &__coupon-item {
      width: 50%;
    }
  }

  @media (max-width: 1100px) {

    &__address-detail {
      flex-wrap: wrap;

      &-col {
        max-width: none;
      }
    }
  }

  @media (max-width: 1000px) {

    &__wrap {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: var(--z-below-blackout);
      width: 100%;
      height: 100%;
      padding-bottom: 180px;
      overflow-y: scroll;
      background-color: #e5e5e5;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__modal {

      &_active {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: var(--z-blackout);
      }
    }

    &__title {
      font-size: 19px;
      line-height: 21px;
    }

    &__address-detail-col {
      max-width: none;
    }

    &__address-detail {
      margin-bottom: 5px;
    }

    &__replacement-title {
      font-size: 16px;
      line-height: 20px;
    }

    &__payment {
      padding: 15px;
      border-radius: 0 0 20px 20px;
    }

    &__payment-value {
      font-size: 12px;
    }

    &__close {
      top: 26px;
    }
  }
  @media (max-width: 900px) {
    max-width: none;

    &__address-detail-col {
      max-width: none;
    }
  }
}

@keyframes modal-grey {

  from {
    background-color: inherit;
  }

  to {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.checkout-card{

  &__logo{
    margin-right: 10px;
  }

  &__type{
    margin-right: 5px;
  }

  &__number::before{
    margin-right: 3px;
    content: '·';
  }
}
</style>

<style lang="postcss">
.checkout {

  .form-element {

    &_modal {

      .form-element__error-text {
        margin-top: 4px;
        font-size: 14px;
        line-height: 16px;
        text-decoration: dashed underline;
        cursor: pointer;
      }
    }
  }
}
</style>
