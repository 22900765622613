<script setup lang="ts">
defineProps<{ title?: string }>()
const emit = defineEmits<{ (event: 'close'): void }>()

function onOverlayClick(e: Event) {
  const isOverlay = (e?.target as HTMLDivElement).classList.contains('modal-bottom')
  if (isOverlay)
    emit('close')
}
</script>

<template>
  <div class="modal-bottom" @click="onOverlayClick">
    <div class="modal-bottom__body">
      <div v-if="title" class="modal-bottom__title">
        {{ title }}
      </div>
      <div class="modal-bottom__close" @click="emit('close')" />
      <div class="modal-bottom__wrap">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
/* стили нельзя делать скоуп т.к в родителе они используются */

.modal-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.1);

  &__body {
    position: absolute;
    bottom: -90px;
    z-index: 1;
    width: 100%;
    padding: 15px 15px 15px;
    background-color: #fff;
    border-radius: 20px 20px 0px 0px;
    animation-name: smooth-modal;
    animation-duration: 0.5s;
  }

  &__title {
    padding-bottom: 15px;
    font-size: 19px;
    font-weight: 500;
    line-height: 21px;
    border-bottom: 1px solid #f3f3f3;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 15px;
    width: 11px;
    height: 11px;
    cursor: pointer;
    background-image: svg-load('./asset/close.svg');
    background-repeat: no-repeat;
    background-size: 11px;
    transition: background-image 0.2s ease-out;

    &:hover {
      background-image: svg-load('./asset/close.svg', fill=#E30613);
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
  }

  &__main{
    margin-top: -5px;

    .modal-bottom__item:last-child{
      padding: 7px 19px 15px;
      margin: 0px -15px 16px;
      border-bottom: 5px solid #f3f3f3;
    }

  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0 0 15px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    border-bottom: 1px solid #f3f3f3;

    &_card{
      padding: 0px 0 8px;
      margin-bottom: 9px;
    }

    &:last-child {
      padding: 0;
      margin-bottom: 2px;
      border-bottom: none;
    }

    &::after {
      position: absolute;
      right: 15px;
      width: 30px;
      height: 30px;
      content: '';
      background-color: #F6F6F6;
      border-radius: 50%;
    }

    &_check::after {
      position: absolute;
      width: 30px;
      height: 30px;
      content: '';
      background-image: svg-load('./asset/check.svg', fill=#00B0B8);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  @media (max-width: 1000px) {

    &__body {
      bottom: 0;
      animation-name: smooth-modal-mobile;
    }
  }
}

@keyframes smooth-modal {

  from {
    bottom: -100%;
  }

  to {
    bottom: -90px;
  }
}

@keyframes smooth-modal-mobile {

  from {
    bottom: -100%;
  }

  to {
    bottom: 0;
  }
}
</style>
